@import '../../common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(black, 0.5);
  z-index: 300;

  > .modal {
    background-color: white;
    border-radius: 2rem;
    border: 1px solid $tint-color;
    padding: 3rem 4rem;
    width: 100%;
    max-width: 56rem;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > h1 {
        font-size: 2rem;
        font-weight: bold;
      }
      > .close {
        cursor: pointer;
      }
    }
    > ul {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > li {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        width: calc(50% - 1rem);

        &:nth-child(2n) {
          margin-left: 1rem;
        }
        &.fullWidth {
          width: calc(100% - 1rem);
        }
        > label {
          font-size: 0.8rem;
        }
        > input {
          margin-top: 4px;
          font-size: 1rem;
          height: 2.4rem;
          padding-left: 4px;
        }
        > textarea {
          margin-top: 4px;
          font-size: 1rem;
          padding-top: 4px;
          padding-left: 4px;
          resize: none;
          height: 12rem;
        }
      }
    }
    > .agreeWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 2rem auto 0;
      width: fit-content;
      cursor: pointer;

      > .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 2px;
        border: 1px solid black;
      }
      > .agreeText {
        font-size: 1rem;
        margin-left: 8px;
      }
    }
    > .button {
      @include button;
      margin: 2rem auto 0;
      background-color: white;
      color: black;
      border: 1px solid black;

      &:hover {
        background-color: darken(white, 10%);
      }
    }
  }
}
