@import '../../common.scss';

.container {
  background-color: #f4f4f4;
  padding-top: 8rem;
  padding-bottom: 8rem;
  overflow: hidden;

  @include mobile {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  > .wrapper {
    @include limiter;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      font-size: 3rem;
      font-weight: bold;
      text-align: center;

      @include mobile {
        font-size: 2.4rem;
      }
    }
    > .diagram {
      margin-top: 4rem;
      width: 100%;

      &.desktop {
        max-width: 64rem;
        display: block;

        @include mobile {
          display: none;
        }
      }
      &.mobile {
        max-width: 32rem;
        display: none;

        @include mobile {
          display: block;
        }
      }
    }
    > ul {
      display: flex;
      flex-direction: row;
      max-width: 80rem;
      margin-top: 4rem;

      @include mobile {
        flex-direction: column;
      }
      > li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 1rem;
        margin: 1rem;
        padding: 4rem;

        > .title {
          font-size: 1.6rem;
          font-weight: bold;
          color: $tint-color;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0.5rem;
            background-color: rgba($tint-color, 0.2);
          }
          &.navy {
            color: $sub-color;

            &::after {
              background-color: rgba($sub-color, 0.2);
            }
          }
        }
        > img {
          margin-top: 2rem;
          width: 100%;
          max-width: 20rem;
        }
        > .button {
          @include button;
          flex-direction: column;
          margin-top: 2rem;
          font-size: 1.6rem;
          width: 20rem;
          height: 6.4rem;
          border-radius: 3.2rem;

          &.navy {
            background-color: $sub-color;

            &:hover {
              background-color: darken($sub-color, 10%);
            }
          }
          > .desc {
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
