@import '../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding-top: 12rem;
  overflow: hidden;

  > .top {
    @include limiter;

    > p {
      color: white;
      font-size: 2.6rem;
      line-height: 1.4;

      > sup {
        color: $tint-color;
      }
    }
    > ul {
      margin-top: 2rem;

      > li {
        color: white;
        font-size: 1.2rem;
        line-height: 1.8;

        > sup {
          color: $tint-color;
        }
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
  > .stream {
    margin: 4rem 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 24rem;
    animation-name: streamDesktop;
    animation-duration: 16s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @include tablet {
      animation-name: streamTablet;
      animation-duration: 13s;
    }
    @include mobile {
      animation-name: streamMobile;
      animation-duration: 10s;
    }
    > ul {
      min-width: 100%;
      position: relative;

      @include tablet {
        min-width: 200%;
      }
      @include mobile {
        min-width: 300%;
      }
      > .balloon {
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0 2rem;
        border-radius: 2.5rem;
        color: white;
        padding-bottom: 2px;
        font-size: 1rem;
        position: absolute;
        white-space: nowrap;

        &.t01 {
          background-color: $tint-color;

          > .arrow {
            border-right-color: $tint-color;
          }
        }
        &.t02 {
          background-color: $sub-color;

          > .arrow {
            border-right-color: $sub-color;
          }
        }
        > .arrow {
          left: 2.5rem;
          bottom: -1rem;
          width: 0;
          height: 0;
          border-bottom: 1rem solid transparent;
          border-right: 1rem solid;
          position: absolute;
          right: -1rem;
        }
      }
      > .ball {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
        height: 7rem;
        border-radius: 3.5rem;
        background-color: #333333;
        color: white;
        font-size: 1rem;
        text-align: center;
        position: absolute;
        padding: 0 1rem;
        line-height: 1.4;
      }
      .b01 {
        top: 0;
        left: 0;
      }
      .b02 {
        top: 32%;
        left: 24%;
      }
      .b03 {
        top: 48%;
        left: 32%;
      }
      .b04 {
        top: 50%;
        left: 56%;
      }
      .b05 {
        top: 24%;
        left: 80%;
      }
      .b06 {
        bottom: 0;
        left: 16%;
      }
      .b07 {
        bottom: 8%;
        left: 88%;
      }
    }
  }
  > .bottom {
    @include limiter;
    margin-top: 4rem;

    > p {
      color: white;
      font-size: 2.6rem;
      line-height: 1.6;

      @include mobile {
        > br {
          display: none;
        }
      }
      > .accent {
        color: $tint-color;
        font-weight: bold;
      }
    }
  }
}

@keyframes streamDesktop {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -200%;
  }
}

@keyframes streamTablet {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -400%;
  }
}

@keyframes streamMobile {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -600%;
  }
}
