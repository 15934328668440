@import '../../common.scss';

.container {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  padding-top: 16rem;

  @include mobile {
    padding-top: 8rem;
  }
  > .wrapper {
    @include limiter;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 0;

    @include mobile {
      flex-direction: column;
      align-items: center;
      flex-flow: column-reverse;
      padding-right: 2rem;
    }
    > .left {
      flex: 1;
      overflow: hidden;

      @include mobile {
        width: 100%;
        max-width: 28rem;
      }
      > h1 {
        color: white;
        font-size: 6rem;
        white-space: nowrap;

        @include mobile {
          font-size: 4rem;
        }
        > .topic {
          color: $tint-color;
        }
      }
      > p {
        margin-top: 2rem;
        color: $tint-color;
        font-size: 1.2rem;
      }
    }
    > .right {
      flex: 1;
      overflow: hidden;

      @include mobile {
        width: 100%;
      }
      > .image {
        height: 32rem;
        position: relative;
        background-image: url('../../assets/images/home/S01/image.png');
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;

        @include mobile {
          height: 24rem;
        }
        > .overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-size: contain;
          background-position: bottom;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity 0.5s;

          &.i01 {
            background-image: url('../../assets/images/home/S01/overlay-01.png');
          }
          &.i02 {
            background-image: url('../../assets/images/home/S01/overlay-02.png');
          }
          &.i03 {
            background-image: url('../../assets/images/home/S01/overlay-03.png');
          }
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}
