@import '../../common.scss';

.container {
  background-color: black;
  padding-top: 16rem;
  padding-bottom: 8rem;

  @include mobile {
    padding-top: 8rem;
    padding-bottom: 4rem;
  }
  > .wrapper {
    @include limiter;

    > h1 {
      color: white;
      font-size: 2rem;
      font-weight: bold;
      text-decoration: underline;
    }
    > p {
      margin-top: 4rem;
      color: white;
      font-size: 3rem;
      font-weight: bold;
    }
  }
  > ul.items {
    @include limiter;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;

    @include mobile {
      padding: 0;
      overflow: scroll;
      scroll-snap-type: x mandatory;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    > li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:first-child) {
        border-left: 1px solid rgba(white, 0.2);

        @include mobile {
          border-left: none;
        }
      }
      @include mobile {
        min-width: 100%;
        scroll-snap-align: start;
      }
      > img {
        width: 100%;
        max-width: 16rem;
      }
      > h2 {
        margin-top: 1rem;
        color: $tint-color;
        font-size: 1.6rem;
        font-weight: bold;
      }
      > p {
        margin-top: 2rem;
        font-size: 1rem;
        color: white;
        text-align: center;
        line-height: 1.8;
      }
    }
  }
  > ul.dots {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;

    @include mobile {
      display: flex;
    }
    > li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: gray;
      margin: 0 8px;

      &.active {
        background-color: $tint-color;
      }
    }
  }
}
