@import '../../common.scss';

.container {
  position: relative;
  padding: 8rem 0;
  overflow: hidden;

  @include mobile {
    padding: 4rem 0;
  }
  > .wrapper {
    @include limiter;

    > .columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }
      > .left {
        > h1 {
          font-size: 4rem;
          font-weight: bold;
        }
        > p {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
      }
      > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 44rem;
        margin-left: 4rem;

        @include mobile {
          margin-top: 4rem;
          margin-left: initial;
        }
        > li {
          margin-right: 4rem;
          margin-bottom: 2rem;

          > .title {
            color: $tint-color;
            font-size: 1rem;
            font-weight: bold;
          }
          > .content {
            margin-top: 1rem;
            font-size: 1.4rem;
            line-height: 1.4;
          }
        }
      }
    }
    > .map {
      margin-top: 2rem;
      height: 24rem;
      background-color: gray;
    }
    > .button {
      @include button;
      margin: 4rem auto 0;
      width: 20rem;
    }
  }
}
