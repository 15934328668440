@import '../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  transition: background-color 0.5s;

  &.scrolled {
    background-color: black;
  }
  > .wrapper {
    @include limiter;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;

    > .logo {
      z-index: 100;

      > img {
        width: 6rem;
        cursor: pointer;
      }
    }
    > .menus {
      display: flex;
      flex-direction: row;
      position: absolute;
      left: 50%;
      background-color: black;
      border-radius: 1rem;
      letter-spacing: 1px;
      transform: translateX(-50%);

      @include mobile {
        justify-content: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-left: 2rem;
        border-radius: 0;
        transform: initial;
        display: none;

        &.open {
          display: flex;
        }
      }
      > .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1rem;
        width: 8rem;
        height: 2.4rem;
        margin-bottom: 2px;
        cursor: pointer;

        &:hover {
          color: $tint-color;
        }
        @include mobile {
          justify-content: flex-start;
          font-size: 4rem;
          width: initial;
          height: initial;
          padding: 1rem 0;
        }
      }
    }
    > .langs {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include mobile {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        border-top: 1px solid rgba(white, 0.1);
        display: none;

        &.open {
          display: flex;
        }
      }
      > .lang {
        display: flex;
        align-items: center;
        color: gray;
        font-size: 0.8rem;
        padding: 0 1rem;
        height: 2rem;
        margin-bottom: 2px;
        cursor: pointer;

        &.active {
          color: white;
          font-weight: bold;
        }
        &:hover {
          color: $tint-color;
        }
        @include mobile {
          font-size: 1.6rem;
          height: 6rem;
          padding: 0 2rem;
        }
      }
      > .bar {
        width: 1px;
        height: 0.8rem;
        background-color: rgba(white, 0.4);

        @include mobile {
          height: 1.6rem;
        }
      }
    }
    > .toggleButton {
      display: none;
      z-index: 100;

      @include mobile {
        display: initial;
      }
    }
  }
}
