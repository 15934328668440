@import '../../common.scss';

.container {
  background: linear-gradient(30deg, $sub-color 0%, #4f67d5 100%);
  padding-top: 12rem;
  padding-bottom: 4rem;
  overflow: hidden;

  > .wrapper {
    @include limiter;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > img {
      position: absolute;
      top: -4em;
      left: -16rem;
      opacity: 0.2;
      width: 88rem;

      @include mobile {
        width: 56rem;
      }
    }
    > .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      > h1 {
        color: white;
        font-size: 2.4rem;
        font-weight: bold;

        @include mobile {
          font-size: 2rem;
        }
      }
      > img {
        margin-top: 2rem;
        width: 28rem;

        @include mobile {
          width: 20rem;
        }
      }
      > p {
        margin-top: 4rem;
        color: white;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.8;

        @include mobile {
          font-size: 1.2rem;
        }
      }
      > .buttons {
        margin-top: 4rem;
        display: flex;
        flex-direction: row;
        z-index: 100;

        @include mobile {
          flex-direction: column;
        }
        > .button {
          @include button;
          flex-direction: column;
          background-color: white;
          color: $sub-color;
          font-size: 1.6rem;
          width: 20rem;
          height: 6.4rem;
          border-radius: 3.2rem;

          &:hover {
            background-color: darken(white, 10%);
          }
          &:not(:first-child) {
            margin-left: 2rem;

            @include mobile {
              margin-top: 2rem;
              margin-left: initial;
            }
          }
          > .desc {
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    > .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;

      > h2 {
        margin-top: 10rem;
        color: white;
        font-size: 2.8rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.6;

        @include mobile {
          margin-top: 8rem;
          font-size: 2.4rem;

          > br {
            display: none;
          }
        }
        > .accent {
          color: $tint-color;
        }
      }
      > p {
        margin-top: 2rem;
        color: white;
        font-size: 1.2rem;
        text-align: center;

        @include mobile {
          font-size: 1rem;
        }
      }
      > .diagram {
        width: 100%;

        &.desktop {
          margin-top: 2rem;
          max-width: 80rem;
          display: block;

          @include mobile {
            display: none;
          }
        }
        &.mobile {
          margin-top: 4rem;
          max-width: 32rem;
          display: none;

          @include mobile {
            display: block;
          }
        }
      }
    }
  }
}
