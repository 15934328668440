// Colors
$tint-color: #ff5c35;
$sub-color: #152a8c;

// Dimensions
$max-width: 96rem;

@mixin limiter {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 2rem;
}
@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $tint-color;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  width: 16rem;
  height: 4rem;
  padding-bottom: 4px;
  border-radius: 2rem;
  cursor: pointer;

  &:hover {
    background-color: darken($tint-color, 10%);
  }
}

// Responsive
@mixin desktop {
  @media screen and (min-width: 1069px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 735px) and (max-width: 1068px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 734px) {
    @content;
  }
}
@mixin tablet_and_mobile {
  @media screen and (max-width: 1068px) {
    @content;
  }
}
