@use 'sass:color';
@import '../../common.scss';

.container {
  background-color: $sub-color;
  padding: 6rem 0;

  > .wrapper {
    @include limiter;

    > h1 {
      color: white;
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.6;
    }
    > .columns {
      padding-top: 8rem;
      display: flex;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }
      > .left {
        flex: 1;

        > .sticky {
          position: relative;

          &.active {
            position: sticky;
            top: 8rem;
            left: 2rem;
          }
          > .bi {
            width: 12rem;
          }
          > .paragraph {
            margin-top: 4rem;

            > p {
              font-size: 1.4rem;
              color: white;
              line-height: 1.6;
            }
          }
          > .button {
            @include button;
            margin-top: 4rem;
          }
        }
      }
      > ul {
        flex: 1;

        @include mobile {
          margin-top: 4rem;
        }
        > li {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 32rem;
          background-color: white;
          border-radius: 1rem;
          padding: 4rem;

          &:not(:first-child) {
            margin-top: 4rem;

            @include mobile {
              margin-top: 2rem;
            }
          }
          @include mobile {
            max-width: initial;
          }
          > img {
            width: 100%;
            max-width: 20rem;
          }
          > h2 {
            margin-top: 2rem;
            color: $tint-color;
            font-size: 1.6rem;
            font-weight: bold;
            text-align: center;
            line-height: 1.4;
          }
          > p {
            margin-top: 1rem;
            font-size: 1rem;
            color: $tint-color;
          }
        }
      }
    }
  }
}
