@import './common.scss';

* {
  margin: 0;
  padding: 0;
  word-break: keep-all;
  outline: none;
  box-sizing: border-box;
  font-family: 'NanumSquareNeo', sans-serif;
  user-select: none;
}
html {
  overflow-x: hidden;

  // Desktop
  @include desktop {
    font-size: 16px;
  }
  // Tablet
  @include tablet {
    font-size: 14px;
  }
  // Mobile
  @include mobile {
    font-size: 12px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
}
ul,
ol {
  list-style: none;
}
