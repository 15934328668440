@import '../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 8rem 0;

  @include mobile {
    padding: 6rem 0;
  }
  > h1 {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;

    @include mobile {
      font-size: 2.4rem;
    }
  }
  > ul {
    @include limiter;
    max-width: 80rem;
    margin-top: 4rem;
    padding: 0;
    border-top: 2px solid white;

    > li {
      padding: 0 1rem;
      border-bottom: 1px solid rgba(white, 0.6);

      > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 6rem;
        cursor: pointer;

        @include mobile {
          height: initial;
          padding: 1rem 0;
        }
        > .mark {
          width: 2.4rem;
          color: $tint-color;
          font-size: 1.6rem;
          font-weight: bold;

          @include mobile {
            font-size: 1.2rem;
          }
        }
        > .question {
          flex: 1;
          color: white;
          font-size: 1.6rem;
          font-weight: bold;

          @include mobile {
            font-size: 1.2rem;
          }
        }
      }
      > .answer {
        color: white;
        font-size: 1.1rem;
        margin-left: 2.4rem;
        line-height: 1.8;
        padding-bottom: 2rem;

        @include mobile {
          font-size: 1rem;
        }
      }
    }
  }
}
