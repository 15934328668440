@import '../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 8rem 0;
  overflow: hidden;

  @include mobile {
    padding: 6rem 0;
  }
  > h1 {
    font-size: 3rem;
    font-weight: bold;

    @include mobile {
      font-size: 2.4rem;
    }
  }
  > p {
    margin-top: 2rem;
    font-size: 1.2rem;
    text-align: center;
  }
  > ul {
    @include limiter;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      width: calc(25% - 2rem);
      margin: 1rem;
      padding: 2rem;
      border-radius: 1rem;

      @include tablet {
        width: calc(33% - 1rem);
        margin: 0.5rem;
      }
      @include mobile {
        width: calc(50% - 1rem);
        margin: 0.5rem;
      }
      > img {
        width: 14rem;

        @include mobile {
          width: 10rem;
        }
      }
      > .title {
        margin-top: 1rem;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
      }
      > .subtitle {
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.4;
        text-align: center;
      }
    }
  }
}
