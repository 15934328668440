@import '../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202020;
  overflow: hidden;

  @include mobile {
    padding: 4rem 0;
  }
  > .top {
    @include limiter;
    display: flex;
    align-items: flex-end;
    height: 8rem;
    padding-bottom: 2rem;

    @include mobile {
      justify-content: center;
      height: initial;
    }
    > img {
      width: 6rem;
    }
  }
  > .line {
    width: 100%;
    height: 1px;
    background-color: rgba(white, 0.2);

    @include mobile {
      display: none;
    }
  }
  > .bottom {
    @include limiter;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include mobile {
      flex-direction: column;
      align-items: center;
      height: initial;
      padding-top: initial;
    }
    > .left {
      display: flex;
      width: 100%;
      max-width: 72rem;
      margin-right: 4rem;
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        max-width: initial;
        margin-right: initial;
      }
      > .item {
        margin-right: 3rem;
        margin-bottom: 3rem;

        @include mobile {
          margin-right: initial;
          margin-bottom: 1rem;
        }
        > .title {
          color: gray;
          font-size: 0.8rem;

          @include mobile {
            text-align: center;
          }
        }
        > .content {
          margin-top: 1rem;
          color: white;
          font-size: 1rem;
          line-height: 1.4;

          @include mobile {
            text-align: center;
          }
        }
      }
    }
    > .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include mobile {
        margin-top: 2rem;
        align-items: center;
      }
      > .copyright {
        color: white;
        font-size: 0.8rem;
        white-space: nowrap;
        letter-spacing: 1px;
      }
    }
  }
}
